import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/review.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`And now it's time for a different kind of rant. With FF XVI in "the final stages of development" and Tetsuya Nomura theoretically not involved in any way with the game, I figured I throw down a few of my desires for the next mainline entry into this long running series I've played so much of, an entry that may decide whether or not I play the next one, or any future spin-offs.`}</p>
    <h3>{`A Coherent Story`}</h3>
    <p>{`This is absolutely, positively, the thing I want most out of this game. These games used to be the pinnacle of video game story telling but the last two, single-player games, have been utter travesties in this regard. Not since XII have they told a compelling story and even that one paled next to X. Final Fantasy XV's story wasn't just bad, it was essentially designed to be incomplete from the start with relevant plot points made in multiple spin-off games, bits of DLC, and a movie. The only thing memorable in XV was putting behemoth chunks in my ramen.`}</p>
    <p>{`Beyond the main plot, I'd give nearly anything to have at least two main characters that I care about in the game. The last truly great character in Final Fantasy? Balthier. The best character? It'd probably be a toss up between Celes and Tidus, as much of a whiner as the latter was in the early parts of X. If SquareEnix can't give me likeable characters I'd gladly settle for a non-emo main character, preferably one not named after some meteorological phenomenon or literally named `}<em parentName="p">{`night`}</em>{`. Bonus points if none of the characters dress like they only buy clothes from the discount rack at their local H&M.`}</p>
    <p>{`Another big boon would be a likeable bad guy. Barthandelus and Ardyn were not likeable or indeed really developed meaningfully at all. The character doesn't even have to be likeable, at least make him memorable. Goodness knows that the likes of Kefka, Sephiroth, and Jecht are not likeable, but each of them stand out in their own way. We've certainly had our share of nobodies as bad guys in good Final Fantasies: Exdeath, Vayne, and Garland are all pretty forgetful, but their games do not suffer for it. Maybe give me another General Leo to root for; someone on the wrong side of the battle.`}</p>
    <h3>{`Music`}</h3>
    <p>{`This is usually a staple of the series but once again, the last two single-player experiences have had lackluster tunes. There was nothing memorable in XIII or XV that was endemic to either game but at least XV managed to make do with selections from all the previous games, purchaseable on CD at your local gas station with your American Express card that you could listen to in your car, the `}<em parentName="p">{`Regalia`}</em>{`. Such a far cry from XII, who's soundtrack was absolutely transcendent. I'm familiar with Masayoshi Soken only through his work on Final Fantasy XIV, and that's definitely a good place to be coming from.`}</p>
    <h3>{`Combat`}</h3>
    <p>{`Based on what we've seen so far, combat in XVI looks like something out of Devil May Cry. Personally I'm down for this. The combat in XV was definitely it's highlight, and if SquareEnix wants to evolve it, let them. With that said, I really want them to lean hard into the DMC aspect of combat, right down to the over the top elements of it. Give me cinematics where the main character throws up a magazine of ammo, empties their current mag, then ejects it and reloads by catching the falling magazine and keeps shooting without missing a beat. It'd also be great to have characters be hot-swappable on the fly so you can unleash some amazing combos. This actually feels almost like a given.`}</p>
    <h3>{`DLC`}</h3>
    <p>{`No. For the love of all that's holy just let this be a one and done experience. If there must be DLC, and these days that feels like it's guaranteed, at least make it only tangentially related to the story and not something that fills in glaring plot holes that were deliberately left out.`}</p>
    <h3>{`Nomura`}</h3>
    <p>{`No. Please stay away.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      